.container {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.615);
  backdrop-filter: blur(10px);
  height: 64px;
  width: 100%;
  color: white;
  overflow-x: auto;
  overflow-y: hidden;
  position: sticky;
  top: 0;

  ul {
    display: flex;
    gap: 10px;
    margin: 0;
    width: 100%;
    list-style: none;
    align-items: center;
    padding: 10px 20px;
  }
}

.link {
  font-size: 24px;
  color: #93bea8;
  text-decoration: none;
}

.gallery {
  margin-left: auto;
}

.logo {
  height: 100%;
  width: auto;
}

.biggest_wood {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 32px;
  font-style: italic;
  font-weight: bold;
  color: #93bea8;
  text-decoration: none;
}
