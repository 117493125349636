.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  background-image: url("./assets/tree.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    justify-self: center;
    color: #93bea8;
    background: #1f2a0c59;
    padding: 20px;
    align-items: center;
    height: 100px;
    margin: 0;
    text-shadow: 0 5px 5px BLACK;
    backdrop-filter: blur(10px);
  }
}
