footer {
  height: fit-content;
  background: black;
  color: #93bea8;
  padding: 20px;

  p {
    display: flex;
    color: #93bea8;
    font-size: 32px;
    justify-content: center;
    font-weight: bold;
  }
}

.copy_write {
  color: #93bea8;
  font-size: 12px;
  font-style: italic;
}
