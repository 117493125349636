.container {
  background: black;
  min-height: 100dvh;
  overflow-x: clip;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
}
