.container {
  // background-color: darkcyan;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  // max-width: 1920px;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), #93bea826);

  img {
    max-width: 500px;
    min-width: 350px;

    height: auto;
  }
}
